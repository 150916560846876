@tailwind base;
@tailwind components;

.carousel{opacity:95%}.carousel:hover{opacity:100%}.control-dots{display:flex;flex-direction:column;left:10px;gap:10px;justify-content:center;top:0}@media screen and (max-width:960px){.carousel .control-dots{transform:none;flex-direction:row;gap:0;left:0;position:absolute;bottom:0;margin:10px 0;padding:0;text-align:center;width:100%;z-index:1}@media (min-width:960px){.carousel .control-dots{bottom:0}}.carousel .control-dots .dot{-webkit-transition:opacity .25s ease-in;-moz-transition:opacity .25s ease-in;-ms-transition:opacity .25s ease-in;-o-transition:opacity .25s ease-in;transition:opacity .25s ease-in;opacity:.3;box-shadow:1px 1px 2px rgba(0,0,0,.9);background:#fff;border-radius:50%;width:8px;height:8px;cursor:pointer;display:inline-block;margin:0 8px}.carousel .control-dots .dot.selected,.carousel .control-dots .dot:hover{opacity:1}}

body{
  text-align: center;
}

.lobster{
  font-family: "Lobster";
}

.geologica{
  font-family: "Geologica";
}

.text-stroke {
  -webkit-text-stroke: 0.1px #000; /* Width and color of the stroke */
}

@keyframes blink {
  0% {
    stroke: orangered;
  }
  40% {
    stroke: gray;
  }
  60% {
    stroke: gray;
  }
  80% {
    stroke: orangered;
  }
  100% {
    stroke-opacity: orangered;
  }
}

.blink {
  animation: blink 1s infinite;
}
@tailwind utilities;